const loadScript = (url, cb) => {
	const script = document.createElement('script')

	script.type = 'text/javascript'

	if (script.readyState) { // IE
		script.onreadystatechange = function () {
			if (script.readyState === 'loaded' || script.readyState === 'complete') {
				script.onreadystatechange = null

				if (typeof cb === 'function') {
					cb()
				}
			}
		}
	} else { // Others
		script.onload = function () {
			if (typeof cb === 'function') {
				cb()
			}
		}
	}

	script.src = url
	document.getElementsByTagName('head')[0].appendChild(script)
}

const isCookieEnabled = () => {
	if (navigator.cookieEnabled) {
		return true
	}

	document.cookie = 'cookietest=1'
	const result = document.cookie.includes('cookietest=')

	document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'

	return result
}

const generateRangeExpression = (ranges, range) => {
	if (range) {
		const expressions = []

		for (const key in ranges) {
			const expression = `${Object.keys(ranges[key])[0]} ${Object.values(ranges[key])[0]}`

			switch (key) {
				case 'subtotal':
					expressions.push(`${range.subtotal} ${expression}`)
					break
				case 'qty':
					expressions.push(`${range.qty} ${expression}`)
					break
			}
		}

		return expressions.join(' && ')
	}

	return false
}

export default {
	loadScript,
	isCookieEnabled,
	getLocalStorage (key) {
		return (isCookieEnabled() ? localStorage.getItem(key) : null)
	},
	setLocalStorage (key, value) {
		if (isCookieEnabled()) {
			localStorage.setItem(key, value)
		}
	},
	removeLocalStorage (key) {
		if (isCookieEnabled()) {
			localStorage.removeItem(key)
		}
	},
	showToast (msg, options) {
		this.$bvToast.hide()
		this.$bvToast.toast(msg, options)
	},
	showErrors (err) {
		if (this.$refs.validator && err.response && err.response.status === 422) {
			this.$refs.validator.setErrors(err.response.data.data.errors)
		} else {
			const errMsg = this.$t('toastMessage.somethingWentWrong')

			console.error(err)
			this.$bvToast.hide()
			this.$bvToast.toast(err.response ? (err.response.data.message || errMsg) : errMsg, {
				title: `${this.$t('oops')}!`,
				variant: 'danger'
			})
		}
	},
	reload () {
		window.location.reload()
	},
	calculateDistance (lat1, lng1, lat2, lng2) {
		if ((lat1 === lat2) && (lng1 === lng2)) {
			return 0
		} else {
			const radlat1 = Math.PI * lat1 / 180
			const radlat2 = Math.PI * lat2 / 180
			const theta = lng1 - lng2
			const radtheta = Math.PI * theta / 180
			let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)

			if (dist > 1) {
				dist = 1
			}

			dist = Math.acos(dist)
			dist = dist * 180 / Math.PI
			dist = dist * 60 * 1.1515
			dist = dist * 1.609344

			return dist
		}
	},
	focusInput (querySelector, timeout) {
		setTimeout(() => document.querySelector(querySelector).focus(), timeout || 0)
	},
	lazyLoad () {
		this.$$('.lazy').each((i, e) => {
			if (this.$f7.lazy.isInViewport(e)) {
				this.$f7.lazy.loadImage(e)
			}
		})
	},
	initFacebookSdk (appId) {
		if (appId && !window.FB) {
			window.fbAsyncInit = function () {
				window.FB.init({
					appId,
					cookie: true,
					xfbml: true,
					version: 'v15.0'
				})
			}

			loadScript('//connect.facebook.net/en_US/sdk.js')
		}
	},
	initGoogleSdk (clientId, googleCallback) {
		if (clientId && !window.google) {
			clientId = clientId.includes('apps.googleusercontent.com') ? clientId : `${clientId}.apps.googleusercontent.com`
			this.loadScript(`//accounts.google.com/gsi/client?hl=${this.locale}`, () => {
					window.google.accounts.id.initialize({
						client_id: clientId,
						callback: googleCallback
					})
				})
		}
	},
	passwordComplexityMsg () {
		return 'The password must contain at least 8 characters minimum'
	},
	showTooltip ($event) {
		this.$f7.tooltip.create({
			targetEl: $event.target,
			text: $event.target.getAttribute('data-tooltip')
		}).show()
	},
	hideTooltip ($event) {
		this.$f7.tooltip.destroy($event.target)
	},
	loadMoreResults (isVisible, entity, functionName) {
		if (isVisible && this[entity].pagination && this[entity].pagination.current_page < this[
			entity
		].pagination.next_page) {
			this[functionName]({ page: this[entity].pagination.next_page })
		}
	},
	validateDiscount (discount, cart) {
		const cartItemsRange = {}
		const cartCategoriesRange = {}

		cart.items.forEach((i) => {
			const variation = i.variations[i.variation_index]

			if (cartCategoriesRange[variation.category_id]) {
				cartCategoriesRange[variation.category_id].subtotal += variation.subtotal
				cartCategoriesRange[variation.category_id].qty += variation.quantity

				if (!cartCategoriesRange[variation.category_id].items.includes(variation.id)) {
					cartCategoriesRange[variation.category_id].items.push(variation.id)
				}
			} else {
				cartCategoriesRange[variation.category_id] = {
					subtotal: variation.subtotal,
					qty: variation.quantity,
					items: [variation.id]
				}
			}

			cartItemsRange[variation.id] = {
				subtotal: variation.subtotal,
				qty: variation.quantity
			}
		})

		for (const key in discount.range) {
			switch (key) {
				case 'subtotal':
					// eslint-disable-next-line
					if (!eval(`${cart.price.subtotal} ${
						Object.keys(discount.range[key])[0]
					} ${
						Object.values(discount.range[key])[0]
					}`)) {
						return false
					}

					break
				case 'qty':
					// eslint-disable-next-line
					if (!eval(`${cart.items.length} ${
						Object.keys(discount.range[key])[0]
					} ${
						Object.values(discount.range[key])[0]
					}`)) {
						return false
					}

					break
				case 'prerequisite':
					if (discount.prerequisite_categories.length && !discount.prerequisite_categories.every((c) => {
						const eligibleItems = cartCategoriesRange[c.toString()]?.items.filter(i => (
							discount.range[key][c]?.excluded_items || []
						).findIndex(ei => +ei.id === i) === -1)

						// eslint-disable-next-line
						return eval(
							generateRangeExpression(discount.range[key][c], cartCategoriesRange[c])
						) && (!eligibleItems || eligibleItems.length > 0)
					})) {
						return false
					}

					if (discount.prerequisite_items.length && !discount.prerequisite_items.every((i) => {
						// eslint-disable-next-line
						return eval(generateRangeExpression(discount.range[key][i], cartItemsRange[i]))
					})) {
						return false
					}

					break
			}
		}

		return true
	},
	storeClosingTime (store) {
		const now = this.$moment()
		const day = now.format('dddd').toLowerCase()

		if (store.timings && store.timings[day] && store.timings[day].length) {
			for (const timing of store.timings[day]) {
				const startTime = this.$moment(timing.start_time, 'HH:mm:ss')
				const endTime = this.$moment(timing.end_time, 'HH:mm:ss')

				if (now.isAfter(startTime) && now.isBefore(endTime)) {
					return `${this.$options.filters.capitalize(this.$t('closes'))}  ${endTime.calendar()}`
				}
			}
		}

		return ''
	},
	storeNextOpeningTime (store) {
		const now = this.$moment()
		const day = now.format('dddd').toLowerCase()
		let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
		const dayIndex = days.indexOf(day)

		days = days.splice(dayIndex).concat([...days, day])

		for (let i = 0; i < days.length; i++) {
			if (store.timings && store.timings[days[i]] && store.timings[days[i]].length) {
				for (const timing of store.timings[days[i]]) {
					const timeArr = timing.start_time.split(':')

					now.set({ h: timeArr[0], m: timeArr[1] }).format('llll')

					if (now > this.$moment()) {
						return `${this.$t('opensAgain')} ${now.calendar()}`
					}
				}
			}

			now.add(1, 'days')
		}

		return ''
	},
	getSocketUrl (url) {
		return url.replace('http', 'ws')
	},
	showCartFullAlert () {
		this.$bvToast.hide()
		this.$bvToast.toast(this.$t('toastMessage.kgLimitForItem', [this.merchant.settings.order_weight_limit]), {
			title: this.$t('alert'),
			variant: 'danger'
		})
	},
	validateConditions (price, conditions) {
		for (const key in conditions) {
			// eslint-disable-next-line
			if (Object.prototype.hasOwnProperty.call(price, key) && !eval(conditions[key].map((condition) => {
				return `${price[key]} ${condition.operator} ${condition.value}`
			}).join(' && '))) {
				return false
			}
		}

		return true
	},
	favorite (item) {
		if (this.user) {
			this.$store.dispatch(
				item.favorite ? 'removeFavorite' : 'addToFavorites', item.id
			).then(() => {
				item.favorite = !item.favorite
			}).catch(this.showErrors)
		} else {
			this.$store.commit('setState', {
				key: 'authComponent',
				value: 'login'
			})
		}
	},
	chunkArray (arr, size) {
		const chunks = []

		arr = Object.assign([], arr)

		while (arr.length) {
			chunks.push(arr.splice(0, size))
		}

		return chunks
	},
	scrollTo (top) {
		window.scrollTo({
			top,
			behavior: 'smooth'
		})
	},
	resetValidator (validator) {
		if (validator) {
			window.requestAnimationFrame(validator.reset)
		}
	},
	modifierComparer (otherArray) {
		return (current) => {
			return otherArray.filter((other) => {
				return other.group_id === current.group_id && other.item_id === current.item_id
			}).length === 0
		}
	},
	toCurrency (price) {
		let currency = this.$store.$i18n.n(price, { style: 'currency', currency: this.$store.state.merchant.settings.general.currency_code, locale: this.$store.state.locale })

		const formatter = new Intl.NumberFormat(this.$store.state.locale, { style: 'currency', currency: this.$store.state.merchant.settings.general.currency_code }).formatToParts(0)

		const intlCurrency = formatter.find(p => p.type === 'currency')?.value || ''

		if (intlCurrency && this.$store.state.merchant.settings.general.currency_symbol && !currency.includes(this.$store.state.merchant.settings.general.currency_symbol)) {
			currency = currency.replace(intlCurrency, this.$store.state.merchant.settings.general.currency_symbol)
		}

		return currency
	}
}
